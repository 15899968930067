<template>
    <header id="header-main" class="header-scrolled">
      <div class="container main-menu">
        <div class="row align-items-center justify-content-between d-flex">
          <div id="logo">
            <router-link to="/">
              <img src="./../assets/img/CompanyName.png" alt title /> 
              <!-- <div class="banner">
                  <div class="wrapper">
                    <p>
                      <img src="./../assets/img/logo.png" alt title />
                      <span class="navTextA">BRM International Co., Ltd.</span>
                      <br/>
                      <span class="navTextB">บริษัท บีอาร์เอ็ม อินเตอร์เนชั่นแนล จำกัด</span>
                    </p>
                  </div>
              </div> -->
              <!-- <h2><img src="./../assets/img/logo.png" alt title /> BRM International</h2> -->
            </router-link>
          </div>
          <nav id="nav-menu-container">
            <ul class="nav-menu">
              <li>
                <router-link to="/about">About</router-link>
              </li>
              <li>
                <router-link to="/products">Products</router-link>
              </li>
              <li>
                <router-link to="/services">Services</router-link>
              </li>
              <li>
                <router-link to="/news">News</router-link>
              </li>
              <li>
                <router-link to="/contact">Contact</router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
</template>

<script>

export default {
  name: 'app-header',
  components: {
  },
  data(){
    return{
    };
  },
  methods: {
    scrollToTop() {
        window.scrollTo(0,0);
    }
  },
  mounted(){
    
  }
}
</script>

<style>
/* .banner { background-color: #000; } */

.wrapper { 
    /* width: 100%; */
    margin: 0 auto;
    margin-bottom: -10px;
  }

.banner p {
  /* font-family: "Gentium Basic"; */
  font-size: 19px;
  text-align: center;
  color: black;
  /* color: #aaa; */
  /* margin-top: -10px; */
  display: inline-block;
 }
.banner img {
  float: left; 
  margin: 5px;
 }
 .navTextA {
  /* padding-top: 50px; */
  /* margin-top: 10px; */
  margin-left: 10px;
  font-size: 19px;
  font-weight: bold;
  vertical-align:middle;
 }
 .navTextB {
  /* padding-top: 50px; */
  font-size: 14px;
  vertical-align:middle;
 }
</style>