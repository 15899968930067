import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './registerServiceWorker'
import Slick from 'vue-slick'
import ArgonDashboard from './plugins/argon-dashboard'
import Vue2Editor from "vue2-editor";
import VueSession from 'vue-session';

Vue.config.productionTip = false;

Vue.use(ArgonDashboard);
Vue.use(Vue2Editor);
Vue.use(VueSession);
Vue.use(Slick);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  if(to.meta != null){
    document.title = to.meta.title;
  }  
  window.scrollTo(0, 0);
  next();
})