<template>
    <div>
      <!-- Button trigger modal -->
      <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
        Launch demo modal
      </button> -->

      <!-- Modal -->
      <div class="modal fade" id="mainModal" tabindex="-1" role="dialog" aria-labelledby="mainModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="mainModalLabel">{{ModalTitle}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ModalMsg}}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'app-modal',
  components: {
  },
  data(){
    return{
      ModalTitle: "Bmroller",
      ModalMsg: "",
      modalActive: false
    };
  },
  methods: {
    showModal(title, msg){
      this.ModalTitle = title;
      this.ModalMsg = msg;
      // this.$bvModal.show('bv-modal');
      $('#mainModal').modal('show');
    }
  },
  mounted(){
    
  }
}
</script>

<style>
/* .banner { background-color: #000; } */

/* .wrapper { 
    margin: 0 auto;
    margin-bottom: -10px;
  }

.banner p {
  font-size: 19px;
  text-align: center;
  color: black;
  display: inline-block;
 }
.banner img {
  float: left; 
  margin: 5px;
 }
 .navTextA {
  margin-left: 10px;
  font-size: 19px;
  font-weight: bold;
  vertical-align:middle;
 }
 .navTextB {
  font-size: 14px;
  vertical-align:middle;
 } */
</style>