<template>
  <div id="app">
    <notifications></notifications>
    <router-view/>
  </div>
</template>

<script>
//--Jquery--
window.$ = window.jQuery = require('jquery')

</script>