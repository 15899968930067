<template>
  <div id="main-layout">
    <app-header></app-header>
    <app-modal ref="mainModal"></app-modal>
    <router-view/>
    <app-footer></app-footer>
  </div>
</template>

<script>
//--Jquery--
window.$ = window.jQuery = require('jquery')

import PageHeader from "./Header.vue";
import PageFooter from "./Footer.vue";
import PageModal from "./Modal.vue";

//--Style--
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import './../assets/css/linearicons.css'
import './../assets/css/font-awesome.min.css'
import './../assets/css/bootstrap.css'
import './../assets/css/magnific-popup.css'
import './../assets/css/nice-select.css'
import './../assets/css/hexagons.min.css'
import './../assets/css/animate.min.css'
import './../assets/css/owl.carousel.css'
import './../assets/css/main.css'

//--Custom js--
// import './../../node_modules/jquery/dist/jquery.js'
import './../../node_modules/bootstrap/dist/js/bootstrap.min.js'
// import './../assets/js/main.js'

//--Jquery--
window.$ = window.jQuery = require('jquery')

import axios from 'axios';
import conf from  "../conf";

export default {
    name: 'main-layout',
    components: {
        "app-header": PageHeader,
        "app-footer": PageFooter,
        "app-modal": PageModal
    },
    data() {
      return {
        StaticData: [],
      };
    },
    methods:{
      getStaticData(){
        if(this.StaticData == [] || this.StaticData == null || this.StaticData == 'undefined' || this.StaticData.length == 0){
          axios.get(conf.ApiURL+`/Site/GetSiteData`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                this.StaticData = res.data;
                // console.log(this.StaticData);
              });
        }
        return this.StaticData;
      },
      showMsg(title,msg){
        this.$refs.mainModal.showModal(title,msg);
      },
      enableMobile(){
        if ($('#nav-menu-container').length) {
          var $mobile_nav = $('#nav-menu-container').clone().prop({
            id: 'mobile-nav'
          });
          $mobile_nav.find('> ul').attr({
            'class': '',
            'id': ''
          });
          $('body').append($mobile_nav);
          $('body').prepend('<button type="button" id="mobile-nav-toggle"><i class="lnr lnr-menu"></i></button>');
          $('body').append('<div id="mobile-body-overly"></div>');
          $('#mobile-nav').find('.menu-has-children').prepend('<i class="lnr lnr-chevron-down"></i>');

          $(document).on('click', '.menu-has-children i', function(e) {
            $(this).next().toggleClass('menu-item-active');
            $(this).nextAll('ul').eq(0).slideToggle();
            $(this).toggleClass("lnr-chevron-up lnr-chevron-down");
          });

          $(document).on('click', '#mobile-nav-toggle', function(e) {
            $('body').toggleClass('mobile-nav-active');
            $('#mobile-nav-toggle i').toggleClass('lnr-cross lnr-menu');
            $('#mobile-body-overly').toggle();
          });

          $(document).click(function(e) {
            var container = $("#mobile-nav, #mobile-nav-toggle");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
              if ($('body').hasClass('mobile-nav-active')) {
                $('body').removeClass('mobile-nav-active');
                $('#mobile-nav-toggle i').toggleClass('lnr-cross lnr-menu');
                $('#mobile-body-overly').fadeOut();
              }
            }
          });
        } else if ($("#mobile-nav, #mobile-nav-toggle").length) {
          $("#mobile-nav, #mobile-nav-toggle").hide();
        }
      }
    },
    mounted() {
      this.enableMobile();
      this.getStaticData();
    }
};
</script>

<style>
#main-layout {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>