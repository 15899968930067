<template>
    <div class="bg-default">

        <!-- Page content -->
        <div class="container normal-padding">
            <slide-y-up-transition mode="out-in" origin="center top">
                <router-view></router-view>
            </slide-y-up-transition>
        </div>

    </div>
</template>
<script>
  import { SlideYUpTransition } from 'vue2-transitions'

  export default {
    name: 'auth-layout',
    components: {
      SlideYUpTransition
    },
    // data() {
    //   return {
    //     year: new Date().getFullYear(),
    //     showMenu: false
    //   }
    // }
  }
</script>
<style>
.normal-padding{
  height: 100%;
  padding-top: 100px;
  padding-bottom: 500px;
  overflow: hidden;
}
</style>
