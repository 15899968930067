<template>
  <div>
    <!-- start footer Area -->
    <footer class="mainFooter">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-footer-widget">
              <div id="logo">
                <img src="./../assets/img/CompanyNameWhite.png" alt title />
              </div>
              <!-- <div id="logo">
                  <h6><img src="./../assets/img/logo.png" alt title />BRM International Co., Ltd.</h6>
              </div>
              <h6>บริษัท บีอาร์เอ็ม อินเตอร์เนชั่นแนล จำกัด</h6> -->
              <!-- <h6>ห้างหุ้นส่วนจำกัด บุญรัศมีการยาง</h6> -->
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-footer-widget">
                <h6>Follow Us</h6>
                <a href="https://www.facebook.com/bmroller" class="footer-social-text">
                    <img src="./../assets/img/fb_logo.png" class="footer-social-logo" />
                    facebook.com/bmroller
                </a>
                <p></p>
                <a href="https://line.me/R/ti/p/%40269cgodj" class="footer-social-text">
                    <img src="./../assets/img/line_official_logo.png" class="footer-social-logo" />
                    @bmroller
                </a>
                <p></p>
                <a href="http://line.me/ti/p/~bmroller" class="footer-social-text">
                    <img src="./../assets/img/line_logo.png" class="footer-social-logo" />
                    bmroller
                </a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-footer-widget">
                <h6>ติดต่อ</h6>
                <span class="d-inline-block text-light"><i class="fa fa-home"></i> 48/77-78 หมู่ 1 ถนนเอกชัย ตำบลคอกกระบือ อำเภอเมือง จังหวัดสมุทรสาคร 74000</span><br/>
                <span class="d-inline-block text-light"><i class="fa fa-phone"></i> 034-824-504, 034-824-241</span><br/>
                <span class="d-inline-block text-light"><i class="fa fa-fax"></i> 034-824-510</span><br/>
                <span class="d-inline-block text-light"><i class="fa fa-mobile"></i> 081-822-2996, 085-241-2123</span><br/>
                <span class="d-inline-block text-light"><i class="fa fa-envelope"></i> bmroller@gmail.com</span>
            </div>
          </div>

          <!-- <div class="col-lg-2 col-md-6 col-sm-6 social-widget">
            <div class="single-footer-widget">
              <h6>Follow Us</h6>
              <p>Let us be social</p>
              <div class="footer-social d-flex align-items-center">
                <a href="#">
                  <i class="fa fa-facebook"></i>
                </a>
                <a href="#">
                  <i class="fa fa-twitter"></i>
                </a>
                <a href="#">
                  <i class="fa fa-dribbble"></i>
                </a>
                <a href="#">
                  <i class="fa fa-behance"></i>
                </a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'app-footer',
  components: {
  },
  data(){
    return{
      Line: null,
      Facebook: null
    };
  },
  methods: {
    getSiteData()
    {
      setTimeout(() => {
        this.StaticData = this.$parent.getStaticData();
        this.Line = this.StaticData[0].Line;
        this.Facebook = this.StaticData[0].Facebook;
      }, 500);
    }
  },
  mounted(){
    
  }
}
</script>
<style>
.mainFooter{
  padding-top: 40px;
  background-color: #04091e;
  padding-bottom: 40px; 
}

.mainFooter h6 {
  color: #fff;
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 600;
}
</style>