<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Admin"
      title="Admin"
    >
      

      <template slot="links">
        <sidebar-item :link="{name: 'Setting', icon: 'ni ni-app text-info', path: '/admin/static'}"/>
        <sidebar-item :link="{name: 'Services', icon: 'ni ni-spaceship text-primary', path: '/admin/services'}"/>      
        <sidebar-item :link="{name: 'Category', icon: 'ni ni-collection text-warning', path: '/admin/category'}"/>    
        <!-- <sidebar-item :link="{name: 'Products', icon: 'ni ni-favourite-28 text-success', path: '/admin/products'}"/>   -->
        <sidebar-item :link="{name: 'News', icon: 'ni ni-notification-70 text-danger', path: '/admin/news'}"/>  
        <sidebar-item :link="{name: 'Slide', icon: 'ni ni-image text-info', path: '/admin/slides'}"/> 
        <sidebar-item :link="{name: 'Customers', icon: 'ni ni-like-2 text-success', path: '/admin/partners'}"/>  
        <!-- <sidebar-item :link="{name: 'Login', icon: 'ni ni-key-25 text-info', path: '/admin/login'}"/> -->

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      }
    },
    mounted(){
      this.Token = this.$session.get('Token');
      if(this.Token == null)
        this.$router.push('/admin/login');
    }
  };
</script>
<style lang="scss">
</style>
