import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
// import AuthLayout from '@/layout/AuthLayout'
import OutsideLayout from '@/layout/OutsideLayout'
import MainLayout from '@/layout/MainLayout'

Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home',
      component: MainLayout,
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import(/* webpackChunkName: "demo" */ './views/Home/HomePage.vue'),
          meta: {title: "หน้าแรก"}
        },
        {
          path: '/about',
          name: 'about',
          component: () => import(/* webpackChunkName: "demo" */ './views/AboutUs.vue'),
          meta: { title: "เกี่ยวกับเรา" } 
        },
        {
          path: '/products',
          name: 'products',
          component: () => import(/* webpackChunkName: "demo" */ './views/Product/ProductCategory.vue'),
          meta: { title: "สินค้า" } 
        },
        {
          path: '/services',
          name: 'services',
          component: () => import(/* webpackChunkName: "demo" */ './views/Service/ServiceList.vue'),
          meta: { title: "บริการ" } 
        },
        {
          path: '/news',
          name: 'news',
          component: () => import(/* webpackChunkName: "demo" */ './views/News/NewsList.vue'),
          meta: { title: "ข่าวและกิจกรรม" } 
        },
        {
          path: '/contact',
          name: 'contact',
          component: () => import(/* webpackChunkName: "demo" */ './views/Contact.vue'),
          meta: { title: "ติดต่อเรา" } 
        },
        { 
          path: "/productbycate/:cate?", 
          name:"productbycate", 
          component: () => import(/* webpackChunkName: "demo" */ './views/Product/ProductList.vue'),
          meta: { title: "สินค้าตามประเภท" } 
        },
        { 
          path: "/product/:name?", 
          name:"product", 
          component: () => import(/* webpackChunkName: "demo" */ './views/Product/ProductDetail.vue'),
          meta: { title: "รายละเอียดสินค้า" } 
        }
      ]
    },
    {
      path: '/admin/',
      redirect: '/admin/services',
      component: DashboardLayout,
      children: [
        {
          path: '/admin/services',
          name: 'admin_services',
          component: () => import(/* webpackChunkName: "demo" */ './views/Services.vue'),
          meta: { title: "Admin Tool" } 
        },
        {
          path: '/admin/static',
          name: 'static',
          component: () => import(/* webpackChunkName: "demo" */ './views/StaticData.vue'),
          meta: { title: "Admin Tool" } 
        },
        {
          path: '/admin/category',
          name: 'category',
          component: () => import(/* webpackChunkName: "demo" */ './views/Categories.vue'),
          meta: { title: "Admin Tool" } 
        },
        {
          path: '/admin/news',
          name: 'admin_news',
          component: () => import(/* webpackChunkName: "demo" */ './views/News.vue'),
          meta: { title: "Admin Tool" } 
        },
        {
          path: '/admin/products',
          name: 'admin_products',
          component: () => import(/* webpackChunkName: "demo" */ './views/Products.vue'),
          meta: { title: "Admin Tool" } 
        },
        {
          path: '/admin/slides',
          name: 'slides',
          component: () => import(/* webpackChunkName: "demo" */ './views/Slides.vue'),
          meta: { title: "Admin Tool" } 
        },
        {
          path: '/admin/partners',
          name: 'partners',
          component: () => import(/* webpackChunkName: "demo" */ './views/Partners.vue'),
          meta: { title: "Admin Tool" } 
        }
      ]
    },
    {
      path: '/admin',
      redirect: '/admin/login',
      component: OutsideLayout,
      children: [
        {
          path: '/admin/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue'),
          meta: { title: "Singin" } 
        }
      ]
    }
  ]
})
